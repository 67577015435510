import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { ARTWORK } from "../../../../types";
import { AWS_API, URL_API_CROSSWORD_PUZZLE,DEFAULT_FONT } from "../../../../config";
import {
  Button,
  Form,
  Input,
  Modal,
  InputNumber,
  Card,
  Switch,
  Space,
  Skeleton,
  message,
  Checkbox,
  Row,
  Col,
} from "antd";
import ColorPicker from "./Scrabble/ColorPicker";
import FontSelector from "../../../Font/FontSelector";
import TagsForm from "./Scrabble/TagsForm";
import axios from "axios";

import SelectBackgroundScrabble from "./Scrabble/SelectBackgroundScrabble";
import AddBackgroundSrabble from "./Scrabble/AddBackgroundSrabble";
import { get } from "lodash";
const ScrabbleSettings = ({ layer }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visibleSetting, setVisibleSetting] = useState(false);
  const [bgsScrabble, setBgsScrabble] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingApply, setLoadingApply] = useState(false);
  const [imageRender, setImageRender] = useState({});

  const showModel = () => {
    setVisibleSetting(true);
  };

  const upload = async (base64) => {
    var file = await fetch(base64)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], "tmp.png", { type: "image/png" }));
    var signedUrl = await getSignedUrl(file.type);
    await axios
      .put(signedUrl.url, file, {
        headers: {
          "Content-Type": file.type,
        },
      })
      .catch((err) => console.error(err));
    return { key: signedUrl.key };
  };

  const getSignedUrl = async (mime) => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    var signedUrl = null;
    await fetch(`${AWS_API}/signedUrl?type=${mime}`, requestOptions)
      .then((response) => response.json())
      .then((res) => (signedUrl = res))
      .catch((err) => console.error(err));
    return signedUrl;
  };

  const handleRender = async () => {
    const {width = 500, height = 500} = layer;
    setLoading(true);
    setImageRender({});
    const values = form.getFieldsValue();
    values.fontFamily = values.fontFamily || DEFAULT_FONT;
    values.width = width;
    values.height = height;
    try {
      const query = await fetch(URL_API_CROSSWORD_PUZZLE, {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values)
      })
      const response = await query.json();
      if(response.errors){
        response.errors.forEach(i => {
          message.error(i)
        });
        setLoading(false);
        return;
      }
      dispatch({
        type: ARTWORK.SET_LAYER,
        payload: {
          ...layer,
          backgroundRender: response.url || "",
        },
      });
      setImageRender(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleApply = async () => {
    const { key } = imageRender;
    setLoadingApply(true);
    const valuesLayer = get(layer, "values", []);
    valuesLayer[0].file.key = key;
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        values: valuesLayer,
      },
    });
    await OnClickSaveSetting();
    setLoadingApply(false);
    setVisibleSetting(false);
  };
  const OnClickSaveSetting = () => {
    const values = form.getFieldsValue();
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        configsScrabble: values,
      },
    });
    setVisibleSetting(false);
  };
  useEffect(() => {
    const values = { ...layer.configsScrabble };
    form.setFieldsValue({ ...values });
    setBgsScrabble(values.backgroundScrabbleImages || []);
  }, []);

  const handleChangeBackgroundScrabbleImages = (v) => {
    const values = form.getFieldsValue();
    values.backgroundScrabbleImages = v;
    if(!v.includes(values.backgroundImage)){
      values.backgroundImage = "";
    }
    if(v.length > 0 && !values.backgroundImage){
      values.backgroundImage = v[0];
    }
    form.setFieldsValue({ ...values });
    setBgsScrabble(v);
  };

  const handleFontChange = (v, key) => {
    const values = form.getFieldsValue();
    values[key] = v;
    form.setFieldsValue({
      ...values,
    });
  };
  const handleChangeTags = (v) => {
    const values = form.getFieldsValue();
    values.tags = v;
    form.setFieldsValue({
      ...values,
    });
  }
  return (
    <>
      <div>
      <Form.Item
            name={["personalized", "required"]}
            valuePropName="checked"
            label="Is required"
            labelCol={{ span: 21 }}
            wrapperCol={{ span: 3 }}
            labelAlign="left"
            style={{ textAlign: "right" }}
          >
            <Checkbox />
          </Form.Item>
        <Modal
          title="Settings"
          centered
          visible={visibleSetting}
          onOk={OnClickSaveSetting}
          onCancel={() => setVisibleSetting(false)}
          footer={[
            <Space key="10">
              <Button 
                key={1} 
                onClick={() => setVisibleSetting(false)}
                style={{
                  borderRadius: 3
                }}
              >
                Cancel
              </Button>
              <Button 
                key={3} 
                type="primary" 
                onClick={OnClickSaveSetting}
                style={{
                  borderRadius: 3
                }}
              >
                Save settings
              </Button>
            </Space>,
          ]}
          width={1111}
          okText={"Save settings"}
          className={"modal_setting modal_setting_crossword"}
        >
          <div className={"body_modal_setting"}>
            <div
              style={{
                height: "100%",
                overflow: "auto",
              }}
              className={"body_modal_setting_item1"}
            >
              <div style={{
                  width: "100%",
                  background: "#f2f2f2",
                  padding: "10px",
                  position: "sticky",
                  top: 0,
                  zIndex: 999999,
                  display: "flex",
                  justifyContent: "flex-end"
              }}>
                <Space>
                  <Button 
                    loading={loading} 
                    onClick={handleRender}
                    style={{
                      borderRadius: 3
                    }}
                  >
                      Render - Check Setting
                  </Button>
                  {/* {imageRender && imageRender.url && (
                    <Button 
                      type="primary" 
                      loading={loadingApply} 
                      onClick={handleApply}
                      style={{
                        borderRadius: 3
                      }}
                    >
                      Apply and Save settings
                    </Button>
                  )} */}
                </Space>
              </div>
              <Form
                layout="vertical"
                form={form}
              >
                <Card className="card-settingt-srabble" title="" bordered={false} style={{ width: "100%" }}>
                  <Form.Item label="Tags" name={["tags"]}>
                    <TagsForm onChange={handleChangeTags} />
                  </Form.Item>
                  <h4>Background Image</h4>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <Form.Item name={["backgroundScrabbleImages"]}>
                      <AddBackgroundSrabble
                        onChange={handleChangeBackgroundScrabbleImages}
                      />
                    </Form.Item>
                    <Form.Item name={["backgroundImage"]}>
                      <SelectBackgroundScrabble
                        bgsScrabble={bgsScrabble}
                        onChangeBgsScrabble={
                          handleChangeBackgroundScrabbleImages
                        }
                      />
                    </Form.Item>
                  </div>
                  <Form.Item label="Background Color Label" name={["titleBackground"]}>
                    <Input  />
                  </Form.Item>
                  <Row>
                    <Col span={8} style={{
                      marginRight: 5
                    }}>
                      <Form.Item label="Max length" name={["maxLength"]}>
                        <InputNumber 
                          style={{
                            width: "100%",
                            
                          }} 
                          min={0} 
                          step={1} 
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Margin" name={["margin"]}>
                        <InputNumber 
                          style={{
                            width: "100%"
                          }} 
                          min={0} 
                          step={1} 
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                 
                 
                </Card>
                
                <Card className="card-settingt-srabble" title={<strong>Border</strong>} bordered={false} style={{ width: "100%" }}>
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Border Color" name={["borderColor"]}>
                        <ColorPicker
                          style={{
                            width: "100%"
                          }} 
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}
                    style={{
                      paddingRight: 5
                    }}
                    >
                      <Form.Item label="Border width" name={["borderWidth"]}>
                        <InputNumber 
                          style={{
                            width: "100%",
                          }} 
                          min={0} 
                          step={1} 
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Border radius" name={["borderRadius"]}>
                        <InputNumber 
                          style={{
                            width: "100%"
                          }} 
                          min={0} 
                          step={1} 
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-settingt-srabble" title={<strong>Shadow</strong>} bordered={false} style={{ width: "100%" }}>
                  <Row>
                    <Col 
                      span={8}
                      style={{
                        paddingRight: 5
                      }}
                    >
                      <Form.Item label="Shadow color" name={["shadowColor"]}>
                        <ColorPicker />
                      </Form.Item>
                    </Col>
                    <Col 
                      span={8}
                      style={{
                        paddingRight: 5
                      }}
                    >
                      <Form.Item label="shadowBlur" name={["shadowBlur"]}>
                        <InputNumber 
                          style={{
                            width: "100%"
                          }} 
                          min={0} 
                          step={1} 
                        />
                      </Form.Item>
                    </Col>
                    <Col 
                      span={8}
                      style={{
                        paddingRight: 5
                      }}
                    >
                      <Form.Item label="Shadow offset X" name={["shadowOffsetX"]}>
                        <InputNumber 
                          style={{
                            width: "100%"
                          }} 
                          min={-255} 
                          max={255} 
                          step={1} 
                        />
                      </Form.Item>
                    </Col>
                    <Col 
                      span={8}
                      style={{
                        paddingRight: 5
                      }}
                    >
                      <Form.Item label="Shadow offset Y" name={["shadowOffsetY"]}>
                        <InputNumber 
                          style={{
                            width: "100%"
                          }} 
                          min={-255} 
                          max={255} 
                          step={1} 
                        />
                      </Form.Item>
                    </Col>
                    <Col 
                      span={8}
                      style={{
                        paddingRight: 5
                      }}
                    >
                      <Form.Item label="Shadow opacity" name={["shadowOpacity"]}>
                        <InputNumber 
                          style={{
                            width: "100%"
                          }} 
                          min={0} 
                          max={1} 
                          step={0.1} 
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-settingt-srabble" title={<strong>Text</strong>} bordered={false} style={{ width: "100%" }}>
                  <Row>
                    <Col 
                    style={{
                      paddingRight: 5
                    }}
                    span={8}>
                      <Form.Item label="Text color" name={["textColor"]}>
                        <ColorPicker />
                      </Form.Item> 
                    </Col>
                    
                    <Col 
                    style={{
                      paddingRight: 5
                    }}
                    span={8}>
                      <Form.Item label="Text opacity" name={["textOpacity"]}>
                        <InputNumber 
                        style={{
                          width: "100%"
                        }} 
                        min={0} max={1} step={0.1} />
                      </Form.Item>
                    </Col>
                    <Col 
                    style={{
                      paddingRight: 5
                    }}
                    span={8}>
                      <Form.Item label="Font size" name={["fontSize"]}>
                        <InputNumber 
                        style={{
                          width: "100%"
                        }} 
                        min={0} step={1} />
                      </Form.Item>
                    </Col>

                    <Col 
                    style={{
                      paddingRight: 5
                    }}
                    span={8}>
                      <Form.Item
                        className="scrabble_font"
                        label="Font family"
                        valuePropName="fontFamily"
                        name={["fontFamily"]}
                      >
                        <FontSelector
                          style={{
                            width: "100%"
                          }} 
                          onSelect={(v) => handleFontChange(v, "fontFamily")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-settingt-srabble" title={<strong>Letter points</strong>}
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col 
                      style={{
                        paddingRight: 5
                      }}
                      span={8}
                    >
                        <Form.Item
                          label="Enabled"
                          valuePropName="checked"
                          name={["letterPointsEnabled"]}
                        >
                          <Switch />
                        </Form.Item>
                    </Col>

                    <Col 
                      style={{
                        paddingRight: 5
                      }}
                      span={8}
                    >
                        <Form.Item label="Font size" name={["letterPointsFontSize"]}>
                          <InputNumber 
                           style={{
                            width: "100%"
                          }} 
                          min={0} step={1} />
                        </Form.Item>
                    </Col>

                    <Col 
                      style={{
                        paddingRight: 5
                      }}
                      span={8}
                    >
                      <Form.Item
                        className="scrabble_font"
                        label="Font family"
                        valuePropName="fontFamily"
                        name={["letterPointsFontFamily"]}
                      >
                        <FontSelector
                          onSelect={(v) =>
                            handleFontChange(v, "letterPointsFontFamily")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </div>
            <div className={"body_modal_setting_item2"}>
              {layer.backgroundRender && !loading ? (
                <img width={"100%"} src={layer.backgroundRender} />
              ) : (
                <>{loading && <Skeleton active />}</>
              )}
            </div>
          </div>
        </Modal>
        <Button
          style={{
            height: "35px",
            width: "100%",
            fontSize: "16px",
            fontWeight: "600",
          }}
          type="primary"
          onClick={showModel}
        >
          Setting
        </Button>
      </div>
    </>
  );
};

export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(ScrabbleSettings);
