import React from "react";
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp } from "react-icons/fa";

const DirectionalPad = ({form, onChange}) => {
    const handelChangeUp = () => {
      const values = form.getFieldsValue();
      let customMarginTopMarker = values.customMarginTopMarker;
      onChange({key: 'customMarginTopMarker', value: customMarginTopMarker - 1});
    }
    const handelChangeRight = () => {
      const values = form.getFieldsValue();
      let customMarginLeftMarker = values.customMarginLeftMarker;
      onChange({key: 'customMarginLeftMarker', value: customMarginLeftMarker + 1});
    }
    const handelChangeLeft = () => {
      const values = form.getFieldsValue();
      let customMarginLeftMarker = values.customMarginLeftMarker;
      onChange({key: 'customMarginLeftMarker', value: customMarginLeftMarker - 1});
    }
    const handelChangeDown = () => {
      const values = form.getFieldsValue();
      let customMarginTopMarker = values.customMarginTopMarker;
      onChange({key: 'customMarginTopMarker', value: customMarginTopMarker + 1});
    }
    return <>
       <div className="dpad">
        <div className="dpad-up">
          <button onClick={handelChangeUp} className="btn-directional-pad">
            <FaArrowUp />
          </button>
        </div>

        <div className="dpad-right">
          <button onClick={handelChangeRight}  className="btn-directional-pad">
            <FaArrowRight />
          </button>
        </div>

        <div className="dpad-left">
          <button onClick={handelChangeLeft}  className="btn-directional-pad">
            <FaArrowLeft />
          </button>
        </div>

        <div className="dpad-down">
          <button onClick={handelChangeDown}  className="btn-directional-pad">
            <FaArrowDown />
          </button>
        </div>
      </div>
    </>
}
export default DirectionalPad;