import {
  Button,
  message,
  Tooltip,
} from "antd";
import React from "react";
import { BsCalendar3 } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import { initNewLayer, uniqueID } from "../../../Utilities/helper";
import {
  URL_API_CALENDAR_PICKER,
} from "../../../../../config";
import axios from "axios";
import { pick } from "lodash";
import { ARTWORK } from "../../../../../types";

const AddCalendarLayer = (props) => {
  const { layers } = props;
  const dispatch = useDispatch();

  const addImageCalendarPicker = async () => {
    const key = "addLayerCalendar";
    message.loading({
      content: "Retrieving the current date...",
      key,
      duration: 0,
    });
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const date = `${day}/${month}/${year}`;

    setTimeout(() => {
      message.loading({
        content: "Creating image...",
        key,
        duration: 0,
      });
    }, 2000);

    const body = {
      date,
      scale: 5,
    };

    const query = await axios
      .post(URL_API_CALENDAR_PICKER, body)
      .then((res) => {
        return res.data;
      });

    const file = {
      id: uniqueID(),
      key: query.key,
    };

    const newImageLayer = initNewLayer();
    newImageLayer.type = "Image";
    newImageLayer.values[0].file = pick(file, ["id", "key"]);
    newImageLayer.imageCalendarPicker = true;
    newImageLayer.personalized = {
      enable: true,
    };
    newImageLayer.title = `Image Calendar Picker  #${
      layers.filter((l) => l.imageCalendarPicker).length + 1
    }`;
    newImageLayer.imageCalendarRender = query.url || "";
    newImageLayer.configsCalendar = {
      date,
      heartUrl: null,
      fontFamily: "Arial",
      color: "#000000",
      fontSizeHeader: 60,
      fontSize: 48,
      margin: 20,
      marginTopDaysOfWeek: 40,
      heightBox: 75,
      width: 800,
      widthMarker: 80,
      customMarginLeftMarker: 0,
      customMarginTopMarker: 0,
      borderColor: "#202537",
      borderWidth: 0,
      shadowColor: "#000000",
      shadowBlur: 0,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      opacity: 1,
      scale: 5,
    };
    dispatch({
      type: ARTWORK.ADD_LAYER,
      payload: newImageLayer,
    });
    message.success({
      content: "Successfully created new Calendar Layer!",
      key,
      duration: 4,
    });
  };
  return (
    <>
      <Tooltip title="Add Image Calendar Picker">
        <Button
          onClick={addImageCalendarPicker}
          style={{
            width: "100%",
            borderRadius: 0,
            borderLeftWidth: 0,
            padding: 0,
          }}
          icon={<BsCalendar3 className="anticon" />}
        ></Button>
      </Tooltip>
    </>
  );
};

export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(AddCalendarLayer);
