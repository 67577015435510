import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { ARTWORK } from "../../../../types";
import {
  AWS_API,
  URL_API_CALENDAR_PICKER,
  ASSET_URL,
} from "../../../../config";
import ColorPicker from "./Scrabble/ColorPicker";
import FontSelector from "../../../Font/FontSelector";
import axios from "axios";
import moment from "moment";
import { get, pick, debounce } from "lodash";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  message,
  Modal,
  Popover,
  Row,
  Skeleton,
  Space,
} from "antd";
import { AiFillSetting } from "react-icons/ai";
import FileField from "../../../upload/FileField";
import { uniqueID } from "../../Utilities/helper";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import DirectionalPad from "./Scrabble/DirectionalPad";
const CalendarPickerSettings = ({ layer }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visibleSetting, setVisibleSetting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingApply, setLoadingApply] = useState(false);
  const [imageRender, setImageRender] = useState({});

  const upload = async (base64) => {
    var file = await fetch(base64)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], "tmp.png", { type: "image/png" }));
    var signedUrl = await getSignedUrl(file.type);
    await axios
      .put(signedUrl.url, file, {
        headers: {
          "Content-Type": file.type,
        },
      })
      .catch((err) => console.error(err));
      return {
        id: uniqueID(),
        key: signedUrl.key,
      };
  };

  function getImageDimensions(base64Image) {
    return new Promise((resolve, reject) => {
      const img = new Image();
  
      img.onload = () => {
        const dimensions = {
          width: img.naturalWidth,
          height: img.naturalHeight
        };
        resolve(dimensions);
      };
  
      img.onerror = () => {
        reject(new Error('Failed to load image.'));
      };
  
      img.src = base64Image;
    });
  }

  const getSignedUrl = async (mime) => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    var signedUrl = null;
    await fetch(`${AWS_API}/signedUrl?type=${mime}`, requestOptions)
      .then((response) => response.json())
      .then((res) => (signedUrl = res))
      .catch((err) => console.error(err));
    return signedUrl;
  };

  const showModel = () => {
    setVisibleSetting(true);
  };

  const handleRender = async () => {
    setLoading(true);
    try {
      const values = form.getFieldsValue();
      values.date = values.date.format("DD/MM/YYYY");
      values.fontFamily = values.fontFamily || "Arial";
      if(get(values, 'heartUrl.key')){
        values.heartUrl = ASSET_URL + values.heartUrl.key;
      }else{
        values.heartUrl = null;
      }
      const query = await fetch(URL_API_CALENDAR_PICKER, {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values)
      });
      const response = await query.json();
      if(response.error){
        setLoading(false);
        message.error(response.message);
        return;
      }
      setImageRender(response);
      dispatch({
        type: ARTWORK.SET_LAYER,
        payload: {
          ...layer,
          imageCalendarRender: response.url || "",
        },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('An error occurred, please try again.');
    }    
  };

  const handleFontChange = (v, key) => {
    const values = form.getFieldsValue();
    values[key] = v;
    form.setFieldsValue({
      ...values,
    });
  };

  const OnClickSaveSetting = () => {
    const values = form.getFieldsValue();
    values.date = values.date.format("DD/MM/YYYY");
    values.fontFamily = values.fontFamily || "Arial";
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        configsCalendar: values,
      },
    });
    setVisibleSetting(false);
  };

  const handleApply = async () => {
    const {key, url} = imageRender;
    if(key){
      setLoadingApply(true);
      const upFile = {
        key,
        id: uniqueID()
      }
      const width = layer.width;
      const configsCalendar = form.getFieldsValue();
      configsCalendar.date = configsCalendar.date.format("DD/MM/YYYY");
      configsCalendar.fontFamily = configsCalendar.fontFamily || "Arial";
      const getSizeImage = await getImageDimensions(url);
      const scaleImg = width / getSizeImage.width;

      const valuesLayer = get(layer, "values", []);
      valuesLayer[0].file = pick(upFile, ["id", "key"]);
      dispatch({
        type: ARTWORK.SET_LAYER,
        payload: {
          ...layer,
          width: getSizeImage.width * scaleImg,
          height: getSizeImage.height * scaleImg,
          values: valuesLayer,
          configsCalendar
        },
      });
      setVisibleSetting(false);
      setLoadingApply(false);
    }
    
  };

  useEffect(() => {
    const values = { ...layer.configsCalendar };
    values.date = moment(values.date, "DD/MM/YYYY");
    form.setFieldsValue({ ...values });
  }, []);

  const handleInputChangeDP = useCallback(
    debounce(() => {
      handleRender();
    }, 1000),
    []
  )

  const handleChangeDP = (value) => {
    const values = form.getFieldsValue();
    values[value.key] = value.value;
    form.setFieldsValue({ ...values });
    handleInputChangeDP()
  }

  return (
    <>
      <div>
        <Form.Item
          name={["personalized", "required"]}
          valuePropName="checked"
          label="Is required"
          labelCol={{ span: 21 }}
          wrapperCol={{ span: 3 }}
          labelAlign="left"
          style={{ textAlign: "right" }}
        >
          <Checkbox />
        </Form.Item>
        <Modal
          title="Settings"
          centered
          visible={visibleSetting}
          onOk={OnClickSaveSetting}
          onCancel={() => setVisibleSetting(false)}
          footer={[
            <Space key="10">
              <Button
                key={1}
                onClick={() => setVisibleSetting(false)}
                style={{
                  borderRadius: 3,
                }}
              >
                Cancel
              </Button>
              <Button
                key={3}
                type="primary"
                onClick={OnClickSaveSetting}
                style={{
                  borderRadius: 3,
                }}
              >
                Save settings
              </Button>
            </Space>,
          ]}
          width={1111}
          okText={"Save settings"}
          className={"modal_setting modal_setting_crossword"}
        >
          <div className={"body_modal_setting"}>
            <div
              style={{
                height: "100%",
                overflow: "auto",
              }}
              className={"body_modal_setting_item1"}
            >
              <div
                style={{
                  width: "100%",
                  background: "#f2f2f2",
                  padding: "10px",
                  position: "sticky",
                  top: 0,
                  zIndex: 999999,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Space>
                  <Button
                    loading={loading}
                    onClick={handleRender}
                    style={{
                      borderRadius: 3,
                    }}
                  >
                    Render - Check Setting
                  </Button>
                  {imageRender && imageRender.url && (
                    <Button
                      type="primary"
                      loading={loadingApply}
                      onClick={handleApply}
                      style={{
                        borderRadius: 3,
                      }}
                    >
                      Apply and Save settings
                    </Button>
                  )}
                </Space>
              </div>
              <Form layout="vertical" form={form}>
                <Card
                  className="card-settingt-srabble"
                  title={<strong>Marker</strong>}
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col
                      span={24}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <h4 style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}>
                        <span>Image</span> 
                        <Popover
                          placement="right"
                          content={
                          <>
                          <Alert style={{
                            fontSize: 14,
                            marginBottom: 10
                          }} message={
                            <>
                              * Please rename the file without special characters and spaces.<br/>
                              * Use square shapes and transparent images for a better appearance.
                            </>
                          } type="warning" />
                          </>} 
                          title={<>*Note</>}>
                          <BsFillPatchQuestionFill  
                            style={{
                              marginLeft: 8
                            }} 
                          />
                        </Popover>:
                      </h4>
                      
                      <div>
                        <Form.Item
                          className="calendar-layer-choose-image"
                          name={"heartUrl"}
                        >
                          <FileField
                            style={{
                              width: 88,
                              height: 80,
                            }}
                            accept=".jpg, .png, .jpeg"
                            loading={true}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Width Marker" name={["widthMarker"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        paddingRight: 5
                      }}
                    >
                      <div 
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start"
                      }}
                      >
                        <div>
                          <Form.Item
                            label="Margin Left"
                            name={["customMarginLeftMarker"]}
                            className="custom-margin"
                            style={{
                              width: 150
                            }}
                          >
                            <InputNumber
                              style={{
                                width: "100%",
                              }}
                              step={1}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Margin Top"
                            name={["customMarginTopMarker"]}
                            className="custom-margin"
                            style={{
                              width: 150
                            }}
                          >
                            <InputNumber
                              style={{
                                width: "100%",
                              }}
                              step={1}
                            />
                          </Form.Item>
                        </div>
                        <div style={{
                          marginLeft: 10
                        }}>
                          <DirectionalPad form={form} onChange={handleChangeDP} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>

                <Card
                  className="card-settingt-srabble"
                  title={<strong>Month and Year</strong>}
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Font size" name={["fontSizeHeader"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                <Card
                  className="card-settingt-srabble"
                  title={<strong>Days Of Week</strong>}
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Color" name={["color"]}>
                        <ColorPicker
                          style={{
                            width: "100%"
                          }} 
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Border Color" name={["borderColor"]}>
                        <ColorPicker
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Shadow Color" name={["shadowColor"]}>
                        <ColorPicker
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        marginRight: 5,
                      }}
                    >
                      <Form.Item label="Date" name={"date"}>
                        <DatePicker
                          style={{
                            width: "100%",
                          }}
                          format={"DD/MM/YYYY"}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                        overflow: 'hidden'
                      }}
                    >
                      <Form.Item
                        className="scrabble_font"
                        label="Font family"
                        valuePropName="fontFamily"
                        name={["fontFamily"]}
                      >
                        <FontSelector
                          style={{
                            width: "50%",
                          }}
                          onSelect={(v) => handleFontChange(v, "fontFamily")}
                        />                        
                      </Form.Item>
                    </Col>

                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Font size" name={["fontSize"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item
                        label="Margin Top Days"
                        name={["marginTopDaysOfWeek"]}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Height Box" name={["heightBox"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                        display: 'none'
                      }}
                    >
                      <Form.Item label="Width" name={["width"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Scale" name={["scale"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={1}
                          max={10}
                          step={0.1}
                        />
                      </Form.Item>
                    </Col>
                    
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Border Width" name={["borderWidth"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Shadow Blur" name={["shadowBlur"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item
                        label="Shadow offset X"
                        name={["shadowOffsetX"]}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={-255}
                          max={255}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item
                        label="Shadow offset Y"
                        name={["shadowOffsetY"]}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={-255}
                          max={255}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col 
                      span={8}
                      style={{
                        paddingRight: 5
                      }}
                    >
                      <Form.Item label="Opacity" name={["opacity"]}>
                        <InputNumber 
                          style={{
                            width: "100%"
                          }} 
                          min={0} 
                          max={1} 
                          step={0.1} 
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </div>
            <div style={{
              padding: 5
            }} className={"body_modal_setting_item2"}>
              {layer.imageCalendarRender && !loading ? (
                
                <img className="image-calendar-preview" width={"100%"} src={layer.imageCalendarRender} />
              ) : (
                <>{loading && <Skeleton active />}</>
              )}
            </div>
          </div>
        </Modal>
        <Button
          style={{
            height: "35px",
            width: "100%",
            fontSize: "16px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          type="primary"
          onClick={showModel}
          icon={
            <AiFillSetting
              style={{
                marginRight: 3,
              }}
            />
          }
        >
          Setting
        </Button>
      </div>
    </>
  );
};

export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(CalendarPickerSettings);
